export default {
  methods: {
    resizeImage(settings, returnBase64 = false) {
      let file = settings.file;

      let height = settings.size;
      let width = settings.size;

      let reader = new FileReader();
      let image = new Image();
      let canvas = document.createElement('canvas');

      let dataURItoBlob = function(dataURI) {
        let bytes =
          dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
        let mime = dataURI
          .split(',')[0]
          .split(':')[1]
          .split(';')[0];
        let max = bytes.length;
        let ia = new Uint8Array(max);
        for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
      };

      let resize = function() {
        let canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d'),
          xStart = 0,
          yStart = 0,
          aspectRadio,
          newWidth,
          newHeight;

        canvas.width = width;
        canvas.height = height;

        aspectRadio = image.height / image.width;

        if (image.height < image.width) {
          //horizontal
          aspectRadio = image.width / image.height;
          (newHeight = height), (newWidth = aspectRadio * height);
          xStart = -(newWidth - width) / 2;
        } else {
          //vertical
          (newWidth = width), (newHeight = aspectRadio * width);
          yStart = -(newHeight - height) / 2;
        }

        ctx.drawImage(image, xStart, yStart, newWidth, newHeight);
        let dataUrl = canvas.toDataURL('image/jpeg');
        return returnBase64 ? dataUrl : dataURItoBlob(dataUrl);
      };

      return new Promise(function(resolve, reject) {
        image.onload = function() {
          return resolve(resize());
        };

        if (typeof file === 'string') {
          if (!file.match(/image.*/)) {
            reject(new Error('Not an image'));
            return;
          }

          image.src = file;
          return;
        }

        if (!file.type.match(/image.*/)) {
          reject(new Error('Not an image'));
          return;
        }

        reader.onload = function(readerEvent) {
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      });
    }
  }
};
